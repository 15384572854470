@font-face {
	font-family: "OneNightSans-Thin";
	src: url("fonts/OneNightSans-Thin.woff2");
}

@font-face {
	font-family: "OneNightSans-Medium";
	src: url("fonts/OneNightSans-Medium.woff2");
}

@font-face {
	font-family: "OneNightSans-Black";
	src: url("fonts/OneNightSans-Black.woff2");
}
